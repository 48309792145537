import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Project from '@/model/Project';
import Page from '@/model/Page';
import { projectStore } from '@/store/ProjectStore';

export default class GeneratedProjectServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _createPDFAndGetDownloadLink(id: number): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createPDFAndGetDownloadLink', rpcParams, false) as Promise<string>
    }

    _getProjects(tags: string[]): Promise<Project[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getProjects', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const projects: Project[] = data.map(project => Object.assign(new Project(), project))
                projectStore.replaceProjects(projects)
                return projects
            } else return Promise.reject()
        })
    }


    _getAllProjects(): Promise<Project[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getAllProjects', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const projects: Project[] = data.map(project => Object.assign(new Project(), project))
                projectStore.replaceProjects(projects)
                return projects
            } else return Promise.reject()
        })
    }


    _createProject(project: Project): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createProject', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Project(), data)
            projectStore.addOrReplaceProject(model)
            return model.id
        })
    }

    _addProjectEditors(id: number, arg1: string[] | null): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('addProjectEditors', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Project(), data)
            projectStore.removeProject(id)
            projectStore.addOrReplaceProject(model)
            return model.id
        })
    }

    _inviteUsers(id: number, invites: any | null): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('inviteUsers', rpcParams, false) as Promise<void>
    }

    _updateProject(project: Project, lotIds: string[] | null, password: string | null): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateProject', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Project(), data)
            projectStore.removeProject(project.id)
            projectStore.addOrReplaceProject(model)
            return model.id
        })
    }

    _updateProjectStage(project: Project, lotIds: string[] | null, password: string | null): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateProjectStage', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Project(), data)
            projectStore.removeProject(project.id)
            projectStore.addOrReplaceProject(model)
            return model.id
        })
    }

    _publishToSIMAP(id: number): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('publishToSIMAP', rpcParams, false) as Promise<void>
    }

    _getProject(id: number): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getProject', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Project(), data)
            projectStore.addOrReplaceProject(model)
            return model.id
        })
    }

    getProjects(tags: string[], refresh?: boolean | number): SWR<Project[], number[]> {
        //@ts-ignore
        const result: SWR<Project[], number[]> = reactive(new SWR<Project[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getProjects' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getProjects[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const projects: Project[] = []
                for (const id of data) {
                    const project: Project | undefined = projectStore.state.projects.get(id)
                    if (project) {
                        projects.push(project)
                    }
                }
                result.data = projects
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getProjects(tags).then((data: Project[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(project => project.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let projects: Project[] = [...projectStore.state.projects.values()]
        if (tags !== undefined && tags !== null) projects = SortAndFilterUtil.filter(projects, { tags: tags })
        
        
        result.data = projects
        return result
    }

    getAllProjects(refresh?: boolean | number): SWR<Project[], number[]> {
        //@ts-ignore
        const result: SWR<Project[], number[]> = reactive(new SWR<Project[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getAllProjects' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getAllProjects[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const projects: Project[] = []
                for (const id of data) {
                    const project: Project | undefined = projectStore.state.projects.get(id)
                    if (project) {
                        projects.push(project)
                    }
                }
                result.data = projects
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getAllProjects().then((data: Project[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(project => project.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let projects: Project[] = [...projectStore.state.projects.values()]
        
        
        
        result.data = projects
        return result
    }

    createProject(project: Project, refresh?: boolean | number): SWR<Project | null, number> {
        //@ts-ignore
        const result: SWR<Project | null, number> = reactive(new SWR<Project | null, number>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_createProject' + JSON.stringify(args)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_createProject[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = projectStore.state.projects.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._createProject(project).then((id: number) => {
                call.data = id
                result.data = projectStore.state.projects.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = projectStore.state.projects.get(cached.data) || null
        }
        return result
    }


    getProject(id: number, refresh?: boolean | number): SWR<Project | null, number> {
        //@ts-ignore
        const result: SWR<Project | null, number> = reactive(new SWR<Project | null, number>())
        const callId: string = '_getProject' + JSON.stringify(id)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = projectStore.state.projects.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getProject(id).then((id: number) => {
                call.data = id
                result.data = projectStore.state.projects.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = projectStore.state.projects.get(id) || null
        return result
    }

}
