import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import User from '@/model/User';
import Page from '@/model/Page';
import { userStore } from '@/store/UserStore';

export default class GeneratedUserServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _setUserState(email: string, state: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setUserState', rpcParams, false).then((data: any) => {
            const model = Object.assign(new User(), data)
            userStore.removeUser(email)
            userStore.addOrReplaceUser(model)
            return model.email
        })
    }

    _changePassword(oldPassword: string | null, token: string | null, newPassword: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('changePassword', rpcParams, false) as Promise<void>
    }

    _sendPasswordResetMail(userNameOrEmail: string | null): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('sendPasswordResetMail', rpcParams, false) as Promise<void>
    }

    _deleteUser(email: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteUser', rpcParams, false).then(() => {
            userStore.removeUser(email)
        })
    }

    _createUser(user: User): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createUser', rpcParams, false).then((data: any) => {
            const model = Object.assign(new User(), data)
            userStore.addOrReplaceUser(model)
            return model.email
        })
    }

    _updateUser(user: User): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateUser', rpcParams, false).then((data: any) => {
            const model = Object.assign(new User(), data)
            userStore.removeUser(user.email)
            userStore.addOrReplaceUser(model)
            return model.email
        })
    }

    _getUser(email: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getUser', rpcParams, false).then((data: any) => {
            const model = Object.assign(new User(), data)
            userStore.addOrReplaceUser(model)
            return model.email
        })
    }

    _getUsers(roles: string[]): Promise<User[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getUsers', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const users: User[] = data.map(user => Object.assign(new User(), user))
                userStore.addOrReplaceUsers(users)
                return users
            } else return Promise.reject()
        })
    }


    createUser(user: User, refresh?: boolean | number): SWR<User | null, string> {
        //@ts-ignore
        const result: SWR<User | null, string> = reactive(new SWR<User | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_createUser' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_createUser[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((email: string) => {
                result.data = userStore.state.users.get(email) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._createUser(user).then((email: string) => {
                call.data = email
                result.data = userStore.state.users.get(email) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = userStore.state.users.get(cached.data) || null
        }
        return result
    }


    getUsers(roles: string[], refresh?: boolean | number): SWR<User[], string[]> {
        //@ts-ignore
        const result: SWR<User[], string[]> = reactive(new SWR<User[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getUsers' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getUsers[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const users: User[] = []
                for (const id of data) {
                    const user: User | undefined = userStore.state.users.get(id)
                    if (user) {
                        users.push(user)
                    }
                }
                result.data = users
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getUsers(roles).then((data: User[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(user => user.email || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let users: User[] = [...userStore.state.users.values()]
        if (roles !== undefined && roles !== null) users = SortAndFilterUtil.filter(users, { roles: roles })
        
        
        result.data = users
        return result
    }

    getUser(email: string, refresh?: boolean | number): SWR<User | null, string> {
        //@ts-ignore
        const result: SWR<User | null, string> = reactive(new SWR<User | null, string>())
        const callId: string = '_getUser' + JSON.stringify(email)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((email: string) => {
                result.data = userStore.state.users.get(email) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getUser(email).then((email: string) => {
                call.data = email
                result.data = userStore.state.users.get(email) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = userStore.state.users.get(email) || null
        return result
    }

}
