import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Location from '@/model/gis/Location';
import Page from '@/model/Page';
import { locationStore } from '@/store/LocationStore';

export default class GeneratedLocationServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _updateLocation(location: Location): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateLocation', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Location(), data)
            locationStore.removeLocation(location.id)
            locationStore.addOrReplaceLocation(model)
            return model.id
        })
    }

    _getLocations(isArchived: boolean): Promise<Location[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getLocations', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const locations: Location[] = data.map(location => Object.assign(new Location(), location))
                locationStore.addOrReplaceLocations(locations)
                return locations
            } else return Promise.reject()
        })
    }


    _createLocation(location: Location): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createLocation', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Location(), data)
            locationStore.addOrReplaceLocation(model)
            return model.id
        })
    }

    _deleteLocation(id: number): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteLocation', rpcParams, false).then(() => {
            locationStore.removeLocation(id)
        })
    }

    _getLocationById(id: number): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getLocationById', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Location(), data)
            locationStore.addOrReplaceLocation(model)
            return model.id
        })
    }

    getLocations(isArchived: boolean, refresh?: boolean | number): SWR<Location[], number[]> {
        //@ts-ignore
        const result: SWR<Location[], number[]> = reactive(new SWR<Location[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getLocations' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getLocations[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const locations: Location[] = []
                for (const id of data) {
                    const location: Location | undefined = locationStore.state.locations.get(id)
                    if (location) {
                        locations.push(location)
                    }
                }
                result.data = locations
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getLocations(isArchived).then((data: Location[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(location => location.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let locations: Location[] = [...locationStore.state.locations.values()]
        if (isArchived !== undefined && isArchived !== null) locations = SortAndFilterUtil.filter(locations, { isArchived: isArchived })
        
        
        result.data = locations
        return result
    }

    createLocation(location: Location, refresh?: boolean | number): SWR<Location | null, number> {
        //@ts-ignore
        const result: SWR<Location | null, number> = reactive(new SWR<Location | null, number>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_createLocation' + JSON.stringify(args)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_createLocation[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = locationStore.state.locations.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._createLocation(location).then((id: number) => {
                call.data = id
                result.data = locationStore.state.locations.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = locationStore.state.locations.get(cached.data) || null
        }
        return result
    }


    getLocationById(id: number, refresh?: boolean | number): SWR<Location | null, number> {
        //@ts-ignore
        const result: SWR<Location | null, number> = reactive(new SWR<Location | null, number>())
        const callId: string = '_getLocationById' + JSON.stringify(id)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = locationStore.state.locations.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getLocationById(id).then((id: number) => {
                call.data = id
                result.data = locationStore.state.locations.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = locationStore.state.locations.get(id) || null
        return result
    }

}
