import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Organization from '@/model/Organization';
import Page from '@/model/Page';
import { organizationStore } from '@/store/OrganizationStore';

export default class GeneratedOrganizationServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getOrganizations(type: string): Promise<Organization[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getOrganizations', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const organizations: Organization[] = data.map(organization => Object.assign(new Organization(), organization))
                organizationStore.addOrReplaceOrganizations(organizations)
                return organizations
            } else return Promise.reject()
        })
    }


    _createOrganization(organization: Organization): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createOrganization', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Organization(), data)
            organizationStore.addOrReplaceOrganization(model)
            return model.id
        })
    }

    _updateOrganization(organization: Organization): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateOrganization', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Organization(), data)
            organizationStore.removeOrganization(organization.id)
            organizationStore.addOrReplaceOrganization(model)
            return model.id
        })
    }

    _getOrganization(id: number): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getOrganization', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Organization(), data)
            organizationStore.addOrReplaceOrganization(model)
            return model.id
        })
    }

    getOrganizations(type: string, refresh?: boolean | number): SWR<Organization[], number[]> {
        //@ts-ignore
        const result: SWR<Organization[], number[]> = reactive(new SWR<Organization[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getOrganizations' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getOrganizations[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const organizations: Organization[] = []
                for (const id of data) {
                    const organization: Organization | undefined = organizationStore.state.organizations.get(id)
                    if (organization) {
                        organizations.push(organization)
                    }
                }
                result.data = organizations
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getOrganizations(type).then((data: Organization[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(organization => organization.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let organizations: Organization[] = [...organizationStore.state.organizations.values()]
        if (type !== undefined && type !== null) organizations = SortAndFilterUtil.filter(organizations, { type: type })
        
        
        result.data = organizations
        return result
    }

    createOrganization(organization: Organization, refresh?: boolean | number): SWR<Organization | null, number> {
        //@ts-ignore
        const result: SWR<Organization | null, number> = reactive(new SWR<Organization | null, number>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_createOrganization' + JSON.stringify(args)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_createOrganization[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = organizationStore.state.organizations.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._createOrganization(organization).then((id: number) => {
                call.data = id
                result.data = organizationStore.state.organizations.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = organizationStore.state.organizations.get(cached.data) || null
        }
        return result
    }


    getOrganization(id: number, refresh?: boolean | number): SWR<Organization | null, number> {
        //@ts-ignore
        const result: SWR<Organization | null, number> = reactive(new SWR<Organization | null, number>())
        const callId: string = '_getOrganization' + JSON.stringify(id)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = organizationStore.state.organizations.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getOrganization(id).then((id: number) => {
                call.data = id
                result.data = organizationStore.state.organizations.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = organizationStore.state.organizations.get(id) || null
        return result
    }

}
