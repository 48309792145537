import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Document from '@/model/Document';
import Page from '@/model/Page';
import { documentStore } from '@/store/DocumentStore';

export default class GeneratedDocumentServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _importDocumentsFromProperty(roomId: number, propertyId: number, documentId: any): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('importDocumentsFromProperty', rpcParams, false) as Promise<void>
    }

    _getUploadLink(roomId: number): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getUploadLink', rpcParams, false) as Promise<string>
    }

    _getDownloadLink(roomId: number, documentName: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getDownloadLink', rpcParams, false) as Promise<string>
    }

    _renameDocument(roomId: number, oldDocumentName: string, newDocumentName: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('renameDocument', rpcParams, false) as Promise<void>
    }

    _deleteDocument(id: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteDocument', rpcParams, false).then(() => {
            documentStore.removeDocument(id)
        })
    }

    _listDocuments(roomId: number): Promise<Document[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('listDocuments', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const documents: Document[] = data.map(document => Object.assign(new Document(), document))
                documentStore.addOrReplaceDocuments(documents)
                return documents
            } else return Promise.reject()
        })
    }


    listDocuments(roomId: number, refresh?: boolean | number): SWR<Document[], string[]> {
        //@ts-ignore
        const result: SWR<Document[], string[]> = reactive(new SWR<Document[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_listDocuments' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_listDocuments[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const documents: Document[] = []
                for (const id of data) {
                    const document: Document | undefined = documentStore.state.documents.get(id)
                    if (document) {
                        documents.push(document)
                    }
                }
                result.data = documents
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._listDocuments(roomId).then((data: Document[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(document => document.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let documents: Document[] = [...documentStore.state.documents.values()]
        if (roomId !== undefined && roomId !== null) documents = SortAndFilterUtil.filter(documents, { roomId: roomId })
        
        
        result.data = documents
        return result
    }

    getDocuments(pageIndex?: number, pageSize?: number): Document[] {
        let documents: Document[] = [...documentStore.state.documents.values()]
        
        
        if (pageSize !== null && pageSize !== undefined) {
            documents = documents.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return documents
    }

    getDocument(id: string): Document | undefined {
        return documentStore.state.documents.get(id)
    }
}
